import { combineReducers } from "redux";

import {
  TokenState,
  UserPayment,
  AccountState,
  IUnauthorized,
  TrainerDetails,
  TokenActionType,
  AccountActionType,
  ListUserActionType,
  UserPaymentsActionType,
  SET_TOKEN,
  CLEAR_TOKEN,
  DELETE_USER,
  SET_LIST_USERS,
  SET_LIST_USERS_REPORT,
  SET_UPDATE_USER,
  CLEAR_LIST_USERS,
  SET_UNAUTHORIZED,
  SET_USER_PROFILE,
  GET_USER_PAYMENT_LIST,
  SET_ACCOUNT_USER_PARAMS,
  CLEAR_ACCOUNT_USER_PARAMS,
  UPDATE_ACCOUNT_USER_PARAMS,
  SET_UPDATE_TRAINER_DETAILS,
  UPDATE_ACCOUNT_USER_PROFILE,
  GET_TRAINER_TRANSACTIONS_LIST,
  SET_LIST_USERS_WITH_PAGE,
  SET_TRAINER_PROFILE,
  SET_LIST_TRAINER_FILTER,
  SET_CHAT_TRAINER_PROFILE,
  SET_CATEGORIES_FILTERS,
  SET_SPECIALITY_FILTERS,
  SET_CERTIFICATE_FILTERS,
  SET_LANGUAGE_FILTERS,
  FiltersInterface,
  SET_USER_NEGATIVE_SESSION_REPORT,
  StripeRequirementsActionType,
  SET_STRIPE_REQUIREMENTS
} from "../../../models";

import { findIndex as lodashFindIndex, remove as lodashRemove } from "lodash";

export const initialTrainerDetailsState: TrainerDetails = {
  bio: "",
  searchable: false,
  profession: "trainer",
  Certifications: [],
  rate_per_session_amount: 0,
  rate_per_session_currency: "USD",
  Specialities: [],
  Languages: [],
  start_year: "",
  title: "",
  packageTypes: [],
  retention_rate : 0,
  Categories:[]
};
export const initialAccountState: AccountState = {
  id: 0,
  email: "",
  firstname: "",
  lastname: "",
  avatar: "",
  reference: "",
  status: false,
  dob: "",
  zipcode: "",
  created_at: "",
  country_id: 0,
  state_id: 0,
  city_id: 0,
  Franchise: [],
  gender: "male",
  password: "",
  confirmPassword: "",
  description: "",
  phone: "",
  role: "",
  royalty_fee: 0,
  TrainerDetails: {
    ...initialTrainerDetailsState,
  },
  os_type: null,
  os_version: null,
};

const initialStateToken: TokenState = {
  type: "",
  token: "",
};

const initialStateUserPayments: UserPayment[] = [];
const initialStateTrainerTransactions: any[] = [];
const initialStateUnauthorized: IUnauthorized = {
  message: "",
  status: 0,
};
const initialStateFilters: FiltersInterface = {
  categories: [],
  specialities: [],
  certificates:[],
  languages: [],
};

const initialStateUsers: AccountState[] = [];

const initialStripeRequirements: any = {}

export default combineReducers({
  data: (state = initialAccountState, action: AccountActionType) => {
    switch (action.type) {
      case SET_ACCOUNT_USER_PARAMS:
        return action.payload;
      case SET_LIST_USERS_WITH_PAGE:
        return action.payload;
      case SET_LIST_TRAINER_FILTER:
        return action.payload;
      case CLEAR_ACCOUNT_USER_PARAMS:
        return initialAccountState;

      case UPDATE_ACCOUNT_USER_PARAMS:
        return Object.assign({}, state, action.payload);

      default:
        return state;
    }
  },
  tokenData: (state = initialStateToken, action: TokenActionType) => {
    switch (action.type) {
      case SET_TOKEN:
        return action.payload;

      case CLEAR_TOKEN:
        return initialStateToken;

      default:
        return state;
    }
  },
  userPayments: (
    state = initialStateUserPayments,
    action: UserPaymentsActionType
  ) => {
    switch (action.type) {
      case GET_USER_PAYMENT_LIST:
        return action.payload;
      default:
        return state;
    }
  },
  trainerTransactions: (
    state = initialStateUserPayments,
    action: UserPaymentsActionType
  ) => {
    switch (action.type) {
      case GET_USER_PAYMENT_LIST:
        return action.payload;
      case GET_TRAINER_TRANSACTIONS_LIST:
        return action.payload;
      default:
        return state;
    }
  },
  usersReport: (
    state: AccountState[] = initialStateUsers,
    action: ListUserActionType
  ) => {
    switch (action.type) {
      case SET_LIST_USERS_REPORT:
        return action.payload;     
    
      default:
        return state;
    }
  },
  usersNegativeSessionReport: (
    state: AccountState[] = initialStateUsers,
    action: ListUserActionType
  ) => {
    switch (action.type) {
      case SET_USER_NEGATIVE_SESSION_REPORT:
        return action.payload;     
    
      default:
        return state;
    }
  },
  users: (
    state: AccountState[] = initialStateUsers,
    action: ListUserActionType
  ) => {
    switch (action.type) {
      case SET_LIST_USERS:
        return action.payload;
      case SET_UPDATE_USER:
        const index = lodashFindIndex(state, { id: action.payload.id });
        // state = state.slice(0);
        if (index > -1) {
          state[index] = Object.assign({}, state[index], action.payload);
        }
        return state;
      case SET_UPDATE_TRAINER_DETAILS:
        const index1 = lodashFindIndex(state, { id: action.payload.id });
        // state = state.slice(0);
        if (index1 > -1) {
          state[index1].TrainerDetails.searchable = action.payload.searchable;
        }
        return state;
      case CLEAR_LIST_USERS:
        return initialStateUsers;

      case DELETE_USER:
        lodashRemove(state, { id: action.payload });
        return state;
      default:
        return state;
    }
  },
  userProfile: (state = initialStateUsers, action: ListUserActionType) => {
    switch (action.type) {
      case SET_USER_PROFILE:
        return action.payload;
      case UPDATE_ACCOUNT_USER_PROFILE:
        return Object.assign({}, state, action.payload);
      default:
        return state;
    }
  },
  trainerProfile: (state = initialAccountState, action: ListUserActionType) => {
    switch (action.type) {
      case SET_TRAINER_PROFILE:
        return action.payload;
      default:
        return state;
    }
  },
  trainerProfileForChat: (state = initialAccountState, action: ListUserActionType) => {
    switch (action.type) {
      case SET_CHAT_TRAINER_PROFILE:
        return action.payload;
      default:
        return state;
    }
  },
  unauthorized: (
    state = initialStateUnauthorized,
    action: ListUserActionType
  ) => {
    switch (action.type) {
      case SET_UNAUTHORIZED:
        return action.payload;

      default:
        return state;
    }
  },
  filters: (
    state: FiltersInterface = initialStateFilters,
    action: ListUserActionType
  ) => {
    switch (action.type) {
      case SET_CATEGORIES_FILTERS:
        return {
          ...state,
          categories: action.payload,
        };
      case SET_SPECIALITY_FILTERS:
        return {
          ...state,
          specialities: action.payload,
        };
      case SET_CERTIFICATE_FILTERS:
        return {
          ...state,
          certificates: action.payload,
        };
      case SET_LANGUAGE_FILTERS:
        return {
          ...state,
          languages: action.payload,
        };
      default:
        return state;
    }
  },
  stripeRequirements: (state:any = initialStripeRequirements,
    action: StripeRequirementsActionType) => {
      switch (action.type) {
        case SET_STRIPE_REQUIREMENTS:
          return {
            ...action.payload
          };
        default:
          return state;
      }
    }
});
