// actionTypes.ts

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const UPDATE_USER_PERMISSION_REQUEST = 'UPDATE_USER_PERMISSION_REQUEST';
export const UPDATE_USER_PERMISSION_SUCCESS = 'UPDATE_USER_PERMISSION_SUCCESS';
export const UPDATE_USER_PERMISSION_FAILURE = 'UPDATE_USER_PERMISSION_FAILURE';

export interface Permission {
  id: number;
  slug: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}
export interface PermissionsApiResponse {
    data: Permission[]; // Array of permissions in the 'data' field
  }
  