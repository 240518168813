// permissionActions.ts
import { Action, Dispatch } from 'redux';
import {
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  UPDATE_USER_PERMISSION_REQUEST,
  UPDATE_USER_PERMISSION_SUCCESS,
  UPDATE_USER_PERMISSION_FAILURE,
  Permission,
  PermissionsApiResponse
} from '../../../models/app/PermissionTypes';
import { startLoading, stopLoading } from "..";
import { getAllPermissions, updateUserPermissions } from '../../../api';
import { START_LOADING, STOP_LOADING } from '../../../models';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../reducers';



export const getPermissions = (): ThunkAction<void, AppState, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: GET_PERMISSIONS_REQUEST });

      startLoading(START_LOADING)(dispatch, getState, null);

      const { xhr } = getAllPermissions();

      const permissions = await xhr;  


      dispatch({
        type: GET_PERMISSIONS_SUCCESS,
        payload: permissions, 
      });

      return true;
    } catch (err) {
      console.error('Error in getPermissions:', err);
      dispatch({
        type: GET_PERMISSIONS_FAILURE,
        payload: err.message || 'Error fetching permissions',
      });
      return false;
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};


export const updatePermissions = (userId: number, permissions: Permission) => {
  return async (dispatch: any, getState: any) => {
    try {
      startLoading(START_LOADING)(dispatch, getState, null);
      const {xhr} = await updateUserPermissions(userId, permissions);
      const response = await xhr;  
      if (response) {
        dispatch({
          type: UPDATE_USER_PERMISSION_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: UPDATE_USER_PERMISSION_FAILURE,
          payload: 'Error updating permissions',
        });
      }
    } catch (err) {
      console.error('Error in updatePermissions:', err);
      dispatch({
        type: UPDATE_USER_PERMISSION_FAILURE,
        payload: err.message || 'Error updating permissions',
      });
    } finally {
      stopLoading(STOP_LOADING)(dispatch, getState, null);
    }
  };
};
