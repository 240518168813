import {
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  Permission,
} from '../../../models/app/PermissionTypes';

export interface PermissionState {
  loading: boolean;
  permissions: Permission[]; // Array to store permissions
  error: string | null;
}

const initialState: PermissionState = {
  loading: false,
  permissions: [],
  error: null,
};

const permissionReducer = (state = initialState, action: any): PermissionState => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
      return { ...state, loading: true };

    case GET_PERMISSIONS_SUCCESS:
      const permissionsData = action.payload || [];
      console.log('Permissions Data in Reducer:', permissionsData);  // Log permissions data
      return {
        ...state,
        loading: false,
        permissions: permissionsData,  // Store the permissions data in state
      };

    case GET_PERMISSIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default permissionReducer;
